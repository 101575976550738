export const breakpointDt = 1200;

export const breakpointDt_home = 900;

export const breakpointDt_mid = 768;
export const breakpointDt2 = 1600;

export const apiUrl = `${process.env.REACT_APP_API_URL}`;
export const KAKAO_AUTH_URL = (REDIRECT_URI) => `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

export const retailCode = 'imp27118636';
export const SUB_CATEGORY = [
    {slug: "collinsday", id: 45},
    {slug: 'fragrance', id: 44},
    {slug: "homewear", id: 43},
    {slug: "hygiene", id: 39},
    {slug: "goods", id: 40},
    {slug: "gift", id: 42},
    {slug: 'all', id: "all"}
]
export const cartAddurl = '/api/cart/item/add';
export const NpayButtonKey = '1AC01EC8-221D-44FA-ABAF-8E4D7F3C11CD';

export const customTaps = [
    {
        slug: 'collins-incense-giftset',
        tabs: [{
            tabName: '선물하기',
            targetDomId: 'gift',
            moveWhenClicked: true,
        },
            {
                tabName: '향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '특징',
                targetDomId: 'features',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            }]
    },
    {
        slug: 'collins-incense',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '추가구성/세트',
                targetDomId: 'additionalItem',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-roomno11-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-donotdisturb-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-welcomedrink-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-splashdiving-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-fluffypillow-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'incense',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'lighter-mini',
        tabs: [
            {
                tabName: '제품 정보',
                targetDomId: 'miniLighter-product',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'miniLighter-detail-info',
                moveWhenClicked: true,
            }]
    },
    {
        slug: 'lighter-mini-random-pr2404',
        tabs: [
            {
                tabName: '제품 정보',
                targetDomId: 'miniLighter-product',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'miniLighter-detail-info',
                moveWhenClicked: true,
            }]
    },
    {
        slug: 'collins-incense-edition-goodtimes',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '스페셜 에디션',
                targetDomId: 'special-edition',
                moveWhenClicked: true,
            },
            {
                tabName: '추가구성/세트',
                targetDomId: 'additionalItem',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-goodtimes-set-a-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '스페셜 에디션',
                targetDomId: 'special-edition',
                moveWhenClicked: true,
            },
            {
                tabName: '추가구성/세트',
                targetDomId: 'additionalItem',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-goodtimes-set-b-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '스페셜 에디션',
                targetDomId: 'special-edition',
                moveWhenClicked: true,
            },
            {
                tabName: '추가구성/세트',
                targetDomId: 'additionalItem',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-goodtimes-pr2404',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '스페셜 에디션',
                targetDomId: 'special-edition',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'FAQ-secure',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-chamber-honeybadger',
        tabs: [
            {
                tabName: '제품 정보',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'incense-chamber-honeybadger-pr2404',
        tabs: [
            {
                tabName: '제품 정보',
                targetDomId: 'insense-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'insense-detail',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'keyring-honeybadger',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'keyring-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '벌꿀오소리 이야기',
                targetDomId: 'honey-badger-story',
                moveWhenClicked: true,
            },
            {
                tabName: '벌꿀오소리 시리즈',
                targetDomId: 'honey-badger-series',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보 안내',
                targetDomId: 'keyring-detail',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'keyring-honeybadger-pr2404',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'keyring-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '벌꿀오소리 이야기',
                targetDomId: 'honey-badger-story',
                moveWhenClicked: true,
            },
            {
                tabName: '벌꿀오소리 시리즈',
                targetDomId: 'honey-badger-series',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보 안내',
                targetDomId: 'keyring-detail',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'secret-wish-set',
        tabs: [
            {
                tabName: '제품 정보',
                targetDomId: 'wish-good-mood',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'number-11',
                moveWhenClicked: true,
            },
            {
                tabName: '키링',
                targetDomId: 'keyring-gallery',
                moveWhenClicked: true,
            },
            {
                tabName: '추가 구성',
                targetDomId: 'mini-lighter',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보/FAQ',
                targetDomId: 'incense-CMIT',
                moveWhenClicked: true,
            },
        ]
    }, {
        slug: 'moodwater-discovery-3',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'moodwater-discovery-3-info',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'moodwater-discovery-3-summary',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'moodwater-yuja',
        tabs: [
            {
                tabName: '프래그런스',
                targetDomId: 'moodwater-yuja-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '제품 소개',
                targetDomId: 'moodwater-yuja-info',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'moodwater-yuja-water-base',
                moveWhenClicked: true,
            },
            {
                tabName: '싱글 노트',
                targetDomId: 'moodwater-yuja-single-note',
                moveWhenClicked: true,
            },
            {
                tabName: '사용 안내',
                targetDomId: 'moodwater-yuja-use',
                moveWhenClicked: true,
            },
            {
                tabName: '구성 및 세트',
                targetDomId: 'moodwater-yuja-set',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'moodwater-yuja-detail-info',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'moodwater-violet',
        tabs: [
            {
                tabName: '프래그런스',
                targetDomId: 'moodwater-violet-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '제품 소개',
                targetDomId: 'moodwater-violet-info',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'moodwater-violet-water-base',
                moveWhenClicked: true,
            },
            {
                tabName: '싱글 노트',
                targetDomId: 'moodwater-violet-single-note',
                moveWhenClicked: true,
            },
            {
                tabName: '사용 안내',
                targetDomId: 'moodwater-violet-use',
                moveWhenClicked: true,
            },
            {
                tabName: '구성 및 세트',
                targetDomId: 'moodwater-violet-set',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'moodwater-violet-detail-info',
                moveWhenClicked: true,
            },
        ]
    }, {
        slug: 'moodwater-juniper',
        tabs: [
            {
                tabName: '프래그런스',
                targetDomId: 'moodwater-juniper-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '제품 소개',
                targetDomId: 'moodwater-juniper-info',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'moodwater-juniper-water-base',
                moveWhenClicked: true,
            },
            {
                tabName: '싱글 노트',
                targetDomId: 'moodwater-juniper-single-note',
                moveWhenClicked: true,
            },
            {
                tabName: '사용 안내',
                targetDomId: 'moodwater-juniper-use',
                moveWhenClicked: true,
            },
            {
                tabName: '구성 및 세트',
                targetDomId: 'moodwater-juniper-set',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'moodwater-juniper-detail-info',
                moveWhenClicked: true,
            },
        ]
    }, {
        slug: 'moodwater-variety-3',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'moodwater-variety-info',
                moveWhenClicked: true,
            },
            {
                tabName: '제품 구성',
                targetDomId: 'moodwater-variety-set',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'moodwater-variety-water-base',
                moveWhenClicked: true,
            },
            {
                tabName: '싱글 노트',
                targetDomId: 'moodwater-variety-single-note',
                moveWhenClicked: true,
            },
            {
                tabName: '남해 유자',
                targetDomId: 'moodwater-variety-yuja',
                moveWhenClicked: true,
            },
            {
                tabName: '프렌치 바이올렛',
                targetDomId: 'moodwater-variety-violet',
                moveWhenClicked: true,
            },
            {
                tabName: '스코티시 주니퍼',
                targetDomId: 'moodwater-variety-juniper',
                moveWhenClicked: true,
            },
            {
                tabName: '사용 안내',
                targetDomId: 'moodwater-variety-use',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'moodwater-variety-detail-info',
                moveWhenClicked: true,
            },
        ]
    },
    {
        slug: 'mood-soap-3scents',
        tabs: [
            {
                tabName: '3가지 향',
                targetDomId: 'moodsoap-variety-info',
                moveWhenClicked: true,
            },
            {
                tabName: '제품 특징',
                targetDomId: 'moodsoap-product-info',
                moveWhenClicked: true,
            },
            {
                tabName: '안정 정보',
                targetDomId: 'moodsoap-variety-safe-info',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'moodsoap-variety-detail-info',
                moveWhenClicked: true,
            },
        ]
    }, {
        slug: 'collins-incense-mix',
        tabs: [
            {
                tabName: '요약',
                targetDomId: 'mix-edition-summary',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'mix-edition-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '5가지 향',
                targetDomId: 'mix-edition-5scent',
                moveWhenClicked: true,
            },
            {
                tabName: '안전정보:FAQ',
                targetDomId: 'mix-edition-safe',
                moveWhenClicked: true,
            },
            {
                tabName: '사용안내',
                targetDomId: 'mix-edition-use',
                moveWhenClicked: true,
            }
        ]
    },
    {
        slug: 'moodwater-60-variety-3',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'nv-variety-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '제품 구성',
                targetDomId: 'nv-variety-set',
                moveWhenClicked: true,
            },
            {
                tabName: '프래그런스',
                targetDomId: 'nv-variety-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'nv-variety-waterbase',
                moveWhenClicked: true,
            },
            {
                tabName: '안전 정보',
                targetDomId: 'nv-variety-safe',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'nv-variety-info',
                moveWhenClicked: true,
            }
        ]
    },
    {
        slug: 'moodwater-60-yuja',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'nv-yuja-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '프래그런스',
                targetDomId: 'nv-yuja-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '활용 가이드',
                targetDomId: 'nv-yuja-guide',
                moveWhenClicked: true,
            },
            {
                tabName: '안전 정보',
                targetDomId: 'nv-yuja-safe',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'nv-yuja-waterbase',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'nv-yuja-info',
                moveWhenClicked: true,
            }
        ]
    },
    {
        slug: 'moodwater-60-violet',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'nv-violet-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '프래그런스',
                targetDomId: 'nv-violet-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '활용 가이드',
                targetDomId: 'nv-violet-guide',
                moveWhenClicked: true,
            },
            {
                tabName: '안전 정보',
                targetDomId: 'nv-violet-safe',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'nv-violet-waterbase',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'nv-violet-info',
                moveWhenClicked: true,
            }
        ]
    },
    {
        slug: 'moodwater-60-juniper',
        tabs: [
            {
                tabName: '제품 소개',
                targetDomId: 'nv-juniper-detail',
                moveWhenClicked: true,
            },
            {
                tabName: '프래그런스',
                targetDomId: 'nv-juniper-fragrance',
                moveWhenClicked: true,
            },
            {
                tabName: '활용 가이드',
                targetDomId: 'nv-juniper-guide',
                moveWhenClicked: true,
            },
            {
                tabName: '안전 정보',
                targetDomId: 'nv-juniper-safe',
                moveWhenClicked: true,
            },
            {
                tabName: '워터 베이스',
                targetDomId: 'nv-juniper-waterbase',
                moveWhenClicked: true,
            },
            {
                tabName: '상세 정보',
                targetDomId: 'nv-juniper-info',
                moveWhenClicked: true,
            }
        ]
    },
];

export const kakaoGiftList = new Map([["collins-incense-giftset", "https://gift.kakao.com/product/3424435"]]);

export const imgExtensions = ['.jpg', '.png', '.jpeg', '.pdf'];
export const maxFileTransferSize = 20 * 1024 * 1024; //20mb

export const HIDDEN_MENU_DATA = [
    {
        "name": "무드 컬렉션",
        "slug": "mood",
        "imgUrl": {
            "img": require('../../img/incenseHome/mood_navi_new.jpg'),
            "webpImg": require('../../img/incenseHome/mood_navi_new.webp')
        },
    },
    {
        "name": "프래그런스",
        "slug": "fragrance",
        "imgUrl": {
            "img": "https://fly.gitt.co/collins/img/hiddenNav/fragrance.jpg",
            "webpImg": "https://fly.gitt.co/collins/img/hiddenNav/fragrance.webp"
        },
    }, {
        "name": "홈웨어",
        "slug": "homewear",
        "imgUrl": {
            "img": "https://fly.gitt.co/collins/img/hiddenNav/homewear.jpg",
            "webpImg": "https://fly.gitt.co/collins/img/hiddenNav/homewear.webp"
        },
    }, {
        "name": "청결용품",
        "slug": "hygiene",
        "imgUrl": {
            "img": "https://fly.gitt.co/collins/img/hiddenNav/hygiene.jpg",
            "webpImg": "https://fly.gitt.co/collins/img/hiddenNav/hygiene.webp"
        },
    }, {
        "name": "브랜드 굿즈",
        "slug": "goods",
        "imgUrl": {
            "img": "https://fly.gitt.co/collins/img/hiddenNav/goods.jpg",
            "webpImg": "https://fly.gitt.co/collins/img/hiddenNav/goods.webp"
        },
    }, {
        "name": "기프트",
        "slug": "gift",
        "imgUrl": {
            "img": "https://fly.gitt.co/collins/img/hiddenNav/gift.jpg",
            "webpImg": "https://fly.gitt.co/collins/img/hiddenNav/gift.webp"
        },
    }, {
        "name": "모든 제품",
        "slug": "all",
        "imgUrl": "",
    }]

export const OUTOFSTOCK_OPTION_SELECT_TEXT = '선택한 상품은 현재 구매 불가능한 상품입니다. 다른 옵션을 선택해주세요. ';
